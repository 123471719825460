import React from "react";
import { Typography } from "@material-ui/core";

const Checklist = () => {
  return (
    <>
      <Typography variant="h4">HomeClix Seasonal Checklist</Typography>
    </>
  );
};

export default Checklist;
